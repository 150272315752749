/* PrimeNG styles */

@import "../node_modules/primeicons/primeicons.css";
@import "../node_modules/primeng/resources/themes/lara-light-blue/theme.css";
@import "../node_modules/primeng/resources/primeng.min.css";

/* 公共样式 */

html {
    height: 100%;
    min-width: 1280px;
    top: 0 !important;
}

body {
    margin: 0;
    font-size: 14px;
    font-family: 'Microsoft Yahei';
    color: #666;
    cursor: default;
    height: 100%;
    top: 0 !important;
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

img {
    vertical-align: middle;
}

input {
    background: none;
    border: none;
    outline: none;
    padding: 0;
    margin: 0;
    font-family: 'Microsoft Yahei';
}

button {
    border: none;
    cursor: pointer;
    outline: none;
}

h2,
h3,
h4,
h5 {
    margin: 0;
    padding: 0;
}

a {
    text-decoration: inherit;
    outline: none;
}

a:hover {
    color: #666;
}

button:focus {
    outline: 0;
}

textarea {
    resize: none;
    outline: none;
    font-family: 'Microsoft Yahei';
}

s {
    text-decoration: none;
}

select {
    cursor: pointer;
}


/* display:block */

.block {
    display: block;
}

::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: #999;
    font-size: 14px;
}

:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #999;
    font-size: 14px;
}

::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #999;
    font-size: 14px;
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #999;
    font-size: 14px;
}


/* input:active{border:none;} */

.left {
    float: left;
}

.right {
    float: right;
}

.flex_c {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
}

.flex_l {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.flex_r {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-end;
}

.flex_s {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
}

.flex_t {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.clear:after {
    display: block;
    content: '';
    height: 0;
    clear: both;
}


/* //滚动条样式优化 */

::-webkit-scrollbar {
    width: 14px;
    height: 14px;
}

::-webkit-scrollbar-track,
::-webkit-scrollbar-thumb {
    border-radius: 999px;
    border: 5px solid transparent;
}

::-webkit-scrollbar-track {
    box-shadow: 1px 1px 5px rgba(0, 0, 0, .2) inset;
}

::-webkit-scrollbar-thumb {
    min-height: 20px;
    background-clip: content-box;
    box-shadow: 0 0 0 5px rgba(0, 0, 0, .2) inset;
}

::-webkit-scrollbar-corner {
    background: transparent;
}


/* 弹窗样式 */


/* .cdk-global-overlay-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    z-index: 1000;
    min-height: 3000px;
}

.cdk-overlay-pane {
    max-width: 100% !important;
}

.mat-dialog-container {
    box-shadow: none !important;
} */

.mat-dialog-container {
    padding: 0 !important;
}

.mat-dialog-container {
    background: none;
    box-shadow: none;
}


/* 上传input */

.uploadImg {
    display: none;
    cursor: pointer;
}


/* select样式 */

select {
    /*Chrome和Firefox里面的边框是不一样的，所以复写了一下*/
    border: solid 1px #e8e8e8;
    padding-left: 5px;
    color: #333;
    font-size: 15px;
    /*很关键：将默认的select选择框样式清除*/
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    /*在选择框的最右侧中间显示小箭头图片*/
    background: url(assets/images/select-arrow.png) no-repeat scroll right center transparent;
    /*为下拉小箭头留出一点位置，避免被文字覆盖*/
    padding-right: 20px;
    outline: none;
}


/*  修改日历控件类型 */

::-webkit-inner-spin-button {
    visibility: hidden;
}


/*这是控制上下小箭头的*/

::-webkit-calendar-picker-indicator {
    /*这是控制下拉小箭头的*/
    /* background: url(assets/images/select-arrow.png); */
    /* border: 1px solid #ccc; */
    /* border-radius: 2px;
  box-shadow: inset 0 1px #fff, 0 1px #eee;
  background-color: #eee; */
    /* background-image: -webkit-linear-gradient(top, #f0f0f0, #e6e6e6); */
    /* color: #666; */
    margin-right: 5px;
}

::-webkit-clear-button {
    /*控制清除按钮*/
    visibility: hidden;
    display: none;
}


/*清除ie的默认选择框样式清除，隐藏下拉箭头*/

select::-ms-expand {
    display: none;
}


/* 样式简写 */

.d_f {
    display: flex;
    align-items: center;
}

.fw_w {
    flex-wrap: wrap;
}

.jc_c {
    justify-content: center;
}

.jc_sb {
    justify-content: space-between;
}

.jc_s {
    justify-content: start;
}

.jc_fe {
    justify-content: flex-end;
}

.fd_c {
    flex-direction: column;
    align-items: initial;
}

.ai_fs {
    align-items: flex-start;
}

.ai_c {
    align-items: center;
}

.ai_fe {
    align-items: flex-end;
}

.f_1 {
    flex: 1;
    overflow: hidden;
}

.o_u {
    overflow: unset;
}

.o_h {
    overflow: hidden;
}

.w_100 {
    width: 100%;
}

.h_100 {
    height: 100%;
}

.mw_mc {
    min-width: max-content;
}

.to_e {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.ta_l {
    text-align: left;
}

.ta_c {
    text-align: center;
}

.ta_r {
    text-align: right;
}

.c_p {
    cursor: pointer;
    user-select: none;
}


/* 自定义tab */

.custom_tabs {
    display: flex;
}

.custom_tab {
    height: 24px;
    font-size: 14px;
    font-weight: bold;
    color: #898989;
    line-height: 19px;
    position: relative;
    margin: 0 15px;
    cursor: pointer;
    user-select: none;
}

.custom_tab:first-child {
    margin-left: unset;
}

.custom_tab:last-child {
    margin-right: unset;
}

.custom_tab.active {
    color: #4241FF;
}

.custom_tab.active::before {
    content: '';
    display: block;
    width: 24px;
    height: 5px;
    background: linear-gradient(135deg, #517BE1 0%, #4445FE 100%);
    border-radius: 6px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
}


/* 自定义按钮 */

[class*="custom_btn"] {
    background: #FFFFFF;
    border-radius: 6px;
    background: transparent;
    border: 1px solid transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px 10px;
    font-size: 12px;
    color: #333;
    line-height: 24px;
    cursor: pointer;
    user-select: none;
}

[class*="custom_btn"][class*="plain"] {
    background: #fff;
    border-color: #5A76FF;
    color: #5A76FF;
}

[class*="custom_btn"][class*="primary"] {
    background: #409eff;
    border: none;
    color: #fff;
}

[class*="custom_btn"][class*="text"] {
    padding: 0;
    color: #5A76FF;
}

/** 自定义密码强度条 */
[class*=password_strength]{
    height: 4px;
    background-color: #ddd;
    width: 200px;
    min-width: 200px;
    border-radius: 5px;  
    position: relative;
    overflow: hidden;
}
[class*=password_strength]::before{
    content: '';
    position: absolute;
    width: 0;
    height: 100%;
    transition:all 0.3s;
}
.password_strength1::before{
    width: 20%;
    background-color: #ff5722; 
}
.password_strength2::before{
    width: 40%;
    background-color: #ff5722; 
}
.password_strength3::before{
    width: 60%;
    background-color: #ffb82f; 
}
.password_strength4::before{
    width: 80%;
    background-color: #5fb878; 
}
.password_strength5::before{
    width: 100%;
    background-color: #5fb878; 
}
[class*=password_strength]::after{
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: repeating-linear-gradient(to right,transparent 0%,transparent 18%,#fff 18%,#fff 20.5%);
}
[class*=strength_box]{
    margin-top: 5px;
    color: #ff5722;
}
.strength_box3{
    color: #ffb82f;
}
.strength_box4{
    color: #5fb878;
}
.strength_box5{
    color: #5fb878;
}
.strength_message{
    min-width: 100%;
}

/* 卡片 */

.custom_card {
    background: #FFFFFF;
    border-radius: 7px;
    border: 1px solid #CBCBCB;
    padding: 20px;
}

.custom_card.hover:hover {
    transform: translateY(-1px);
    box-shadow: 0px 5px 8px 0px rgb(0 0 0 / 9%);
}

.custom_card+.custom_card {
    margin-top: 16px;
}


/* 文字折叠 */

.custom_textfold_box {
    display: flex;
    line-height: 20px;
    max-height: 40px;
}

.custom_textfold {
    max-height: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    word-break: break-all;
    position: relative;
}

.expansion .custom_textfold {
    display: initial;
    max-height: unset !important;
}

.custom_textfold::before {
    content: '';
    float: right;
    height: calc(100% - 20px);
}

.custom_textfold::after {
    content: '';
    position: absolute;
    width: 200vw;
    height: 100vh;
    box-shadow: inset -100vw calc(20px - 100vh) 0 0 #fff;
    margin-left: -100vw;
}

.expansion .custom_textfold::after {
    content: none
}

.custom_textfold_btn {
    color: #5A76FF;
    float: right;
    clear: both;
    cursor: pointer;
    user-select: none;
}

.expansion .custom_textfold_btn {
    float: unset;
    clear: unset;
    text-align: right;
}

.custom_textfold_btn .fold_btn {
    display: none;
}

.expansion .custom_textfold_btn.expansion_btn {
    display: none;
}

.expansion .custom_textfold_btn.fold_btn {
    display: block;
}

.dialog_close_btn {
    position: absolute;
    top: 31px;
    right: 31px;
    cursor: pointer;
    user-select: none;
}


/* 引导部分公共样式 */

.guide-head {
    height: 160px;
    border-bottom: 1px solid #e8e8e8;
}

.guide-head.guide-step ul {
    width: 700px;
}

.guide-head ul {
    height: 100%;
    width: 700px;
    margin: 0 auto;
    position: relative;
}

.guide-head li {
    width: 232px;
    height: 100%;
    float: left;
    text-align: center;
    position: relative;
    z-index: 1;
    color: #333;
}

.guide-head li span {
    display: inline-block;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background: #ddd;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    line-height: 28px;
    margin: 70px 0 20px 0;
}

.guide-head li span.isHeadActive {
    background: #5e4fff;
}

.guide-head li span img {
    width: 100%;
    vertical-align: baseline;
}

.guide-head.guide-step ul:after {
    width: 460px;
}

.guide-head ul:after {
    position: absolute;
    content: '';
    top: 84px;
    left: 114px;
    width: 460px;
    height: 2px;
    background: #544a9c;
}


/* 底部 */

.guide-foot {
    height: 57px;
    line-height: 57px;
    text-align: center;
    padding: 0 30px;
    border-top: 1px solid #e8e8e8;
}

.guide-foot span {
    color: #5e4fff;
    font-weight: bold;
}


/* 按钮样式 */

.block-button {
    margin: 110px 0 65px 0;
    height: 67px;
    text-align: center;
}

.block-button button {
    width: 260px;
    height: 100%;
    background: #5e4fff;
    border-radius: 4px;
    font-size: 20px;
    color: #fff;
    font-weight: bold;
}


/* 首页头部 */

.contentRight-header {
    height: 56px;
    line-height: 56px;
    padding-left: 20px;
    background: #fff;
    color: #666;
    font-size: 16px;
    margin-bottom: 20px;
    /* position: fixed;
  top: 70px;
  z-index: 100;
  width: 100%; */
    /* position: relative; */
}

.contentRight-header img {
    vertical-align: middle;
    padding: 0 10px 0 10px;
    cursor: pointer;
}


/* 中间部分 */

.homepage-block {
    margin-left: 18px;
    margin-bottom: 8px;
    position: relative;
    /* margin-top: 70px; */
}


/* 分页部分 */

.paging-block {
    background: #fff;
    padding: 0 20px;
    margin-bottom: 20px;
}

.paging-block.isPageHeight {
    height: 110px;
}


/* 没有内容提示部分样式 */

.none-block {
    text-align: center;
    /*margin-bottom:50px;*/
    padding-bottom: 30px;
    color: #333;
    background: #ffffff
}

.none-block img {
    width: 300px;
    margin: 120px 0 20px 0;
}

.none-block-empty {
    text-align: center;
    padding-bottom: 30px;
    color: #333;
}

.none-block-empty img {
    margin: 120px 0 20px 0;
}

.none-block-empty p {
    font-size: 14px;
    color: #96AEB5;
}

.none-block .none-btn {
    padding: 6px 8px;
    background: #5e4fff;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
    display: inline-block;
    margin: 0 5px;
}


/* 匹配关键词列表样式 */

.keyup-block {
    width: 100%;
    padding: 15px 0;
    background: #fff;
    position: relative;
    text-align: left;
    z-index: 3;
}

.keyup-list {
    line-height: 36px;
    padding: 0 20px;
    font-size: 14px;
    border-bottom: 1px solid #e8e8e8;
    cursor: pointer;
}


/* 校园+公共样式部分 */

.college-block {
    width: 100%;
    background: #fff;
}

.college-block-head {
    padding: 15px 20px;
    color: #5e4fff;
    font-size: 18px;
    border-bottom: 1px solid #e8e8e8;
}

.college-block-head p {
    color: #5e4fff;
    font-size: 20px
}

.college-block-head .college-tab span {
    font-size: 16px;
    margin-right: 20px;
}

.college-block-opera.clear {
    padding: 15px 20px;
    line-height: 40px;
}

.college-block-opera .left select {
    width: 160px;
    height: 40px;
    padding-left: 12px;
    border: 1px solid #e8e8e8;
    margin-left: 15px;
    border-radius: 4px;
}

.select-all {
    display: inline-block;
    padding-left: 25px;
    background: url(assets/images/select-img.png) no-repeat left center;
    cursor: pointer;
}


/* .college-block-opera .left button{
  width:122px;
  height:40px;
  background: #5e4fff;
  border-radius:2px;
  color:#fff;
  font-size:16px;
  margin-left: 15px;
}
.college-block-opera .left button:nth-child(3){
  background: none;
  color: #666;
  margin-left: 5px;
  width: auto;
} */

.college-block-opera .right {
    width: 100%;
    text-align: right;
}

.college-block-opera .right select {
    width: 160px;
    height: 40px;
    padding-left: 12px;
    border: 1px solid #e8e8e8;
    margin-left: 15px;
    border-radius: 4px;
}

.college-resume-filter {
    height: 40px;
    padding: 20px 30px;
    background: #fff;
    line-height: 40px;
    font-size: 16px;
    color: #333;
}

.college-resume-head {
    padding: 20px 30px;
    background: #fff;
    line-height: 40px;
    font-size: 16px;
    color: #333;
    border-bottom: 1px solid #e8e8e8;
}

.college-resume-head select {
    width: 160px;
    height: 40px;
    padding-left: 12px;
    border: 1px solid #e8e8e8;
    margin-left: 15px;
    border-radius: 4px;
    cursor: pointer;
}

.college-resume-filter select {
    width: 160px;
    height: 40px;
    padding-left: 12px;
    border: 1px solid #e8e8e8;
    margin-left: 15px;
    border-radius: 4px;
    cursor: pointer;
}


/* 简历列表样式 */

.college-resume-cont ul {
    padding: 0 20px;
    background: #fff;
    height: 80px;
    line-height: 80px;
}

.college-resume-cont .resume-li:nth-child(odd) {
    background: #f7f7f7;
}

.college-resume-cont ul li {
    float: left;
    height: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.college-resume-cont li:nth-child(1) {
    width: 90px;
}

.college-resume-cont li:nth-child(1) img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.college-resume-cont li:nth-last-child(1) {
    text-align: right
}

.college-resume-cont li:nth-last-child(1) span {
    display: inline-block;
    margin-right: 12px;
    color: #6977eb;
    cursor: pointer;
}

.college-resume-cont li:nth-last-child(1) span:nth-last-child(1) {
    margin-right: 0;
}

.college-resume-filter button {
    float: right;
    width: 120px;
    height: 40px;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    background: #5e4fff;
    border-radius: 4px;
}

.search-input.right {
    height: 40px;
    line-height: 40px;
    width: 376px;
    margin-right: 25px;
}

.search-input span {
    display: inline-block;
    width: 44px;
    height: 40px;
    text-align: center;
    background: #5e4fff;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    cursor: pointer;
}

.search-input input {
    width: calc(100% - 58px);
    height: 38px;
    padding-left: 12px;
    border: 1px solid #e8e8e8;
    border-right: none;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
    background: #fff;
    vertical-align: bottom;
}

.search-more {
    color: #666;
    cursor: pointer;
    font-size: 16px;
}

.search-more img {
    padding: 0 10px;
}

.search-more.show-filter img {
    transform: rotateX(180deg);
}


/* 中间部分 */

.college-list-cont ul {
    padding: 0 20px;
    background: #fff;
    height: 80px;
    line-height: 80px;
}

.college-list-cont .college-li:nth-child(odd) {
    background: #f7f7f7;
}

.college-list-cont .college-li {
    color: #333;
}

.college-list-cont li {
    float: left;
    height: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.college-list-cont li span.skill-block {
    display: inline-block;
    width: 100%;
    height: 100%;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
}

.college-list-cont li span.skill-block i {
    font-style: normal;
    color: #5e4fff;
    border: 1px solid #5e4fff;
    height: 20px;
    line-height: 20px;
    border-radius: 15px;
    display: inline-block;
    padding: 0 8px;
    margin-right: 5px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.college-list-cont li:nth-last-child(1) {
    text-align: right
}

.college-list-cont li:nth-last-child(1) span {
    display: inline-block;
    margin-right: 12px;
    color: #6977eb;
    cursor: pointer;
}

.college-list-cont li:nth-last-child(1) span.gray {
    color: #999;
}

.college-list-cont li:nth-last-child(1) span:nth-last-child(1) {
    margin-right: 0;
}

.college-resume-cont ul li span.red {
    color: #f36270;
}


/* 简历详情样式 */

.container {}

.up-block {
    width: 100%;
    text-align: center;
    position: relative;
    min-width: 1380px;
    background-color: #ffffff;
}

.up-block .left-block img {
    width: 130px;
    height: 130px;
    border-radius: 50%;
    margin-top: 40px;
}

.up-block .right-block .name-text {
    font-size: 30px;
    color: #333;
    margin-top: 9px;
    line-height: 60px;
}

.up-block .right-block .info-text {
    line-height: 66px;
    color: #333;
    font-size: 16px;
}

.up-block .right-block .info-text:nth-child(3) {
    line-height: 24px;
}

.up-block .right-block .info-text button {
    width: 120px;
    height: 36px;
    color: #666;
    font-size: 14px;
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    background: none;
    margin-top: 30px;
}

.up-block .right-block .info-text button.purple {
    background: #5e4fff;
    border: none;
    color: #fff;
    margin-right: 10px;
}

.up-block .right-block .info-text img {
    width: 17px;
}

.up-block .right-block .info-text span {
    margin-left: 10px;
}

.up-block .top-block {
    position: absolute;
    top: 40px;
    right: 10px;
}

.up-block .top-block button {
    background: none;
    border: 1px solid #e8e8e8;
    padding: 5px 15px;
    margin-right: 20px;
    font-size: 12px;
    color: #333;
    border-radius: 4px;
    line-height: 20px;
}

.up-block .top-block img {
    margin-right: 6px;
    vertical-align: top;
}


/* 模块部分样式 */

.resume-model-block {
    width: 800px;
    margin: 0 auto;
}

.resume-model-block .model-title {
    height: 36px;
    margin: 36px 0;
}

.resume-model-block .model-title b {
    width: 318px;
    height: 1px;
    background: #e8e8e8;
    display: inline-block;
    vertical-align: middle;
}

.resume-model-block .model-title span {
    display: inline-block;
    width: 124px;
    height: 36px;
    background: #eee;
    border-radius: 18px;
    text-align: center;
    line-height: 36px;
    font-size: 16px;
    color: #333;
    margin: 0 20px;
}

.model-cont {
    margin-bottom: 20px;
}

.model-text {
    color: #333;
    line-height: 36px;
}

.model-text .text-title {
    font-size: 18px;
}

.model-text.gray {
    color: #666;
}

.model-text.text-description {
    font-size: 14px;
}


/* 技能标签样式 */

.label-item {
    border: 1px solid #5e4fff;
    color: #5e4fff;
    border-radius: 8px;
    padding: 10px 15px;
    float: left;
    margin-right: 29px;
    margin-bottom: 20px;
}


/* 图片样式 */

.model-pic {
    padding-left: 70px;
}

.model-pic img {
    height: 200px;
    margin: 0 10px 10px 0;
}


/* button公共样式 */

button {
    padding: 10px 20px;
    color: #666;
    font-size: 14px;
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    background: none;
}

button.gray {
    background: #aaa;
    border: none;
    color: #fff;
    border-radius: 4px;
}

button.purple {
    background: #5e4fff !important;
    border: none;
    color: #fff !important;
    border-radius: 4px;
}

button.yellow {
    background: #f4c42c;
    border: none;
    color: #fff;
    border-radius: 4px;
}


/* 弹出框公共样式 */

.model-block {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
}

.model-block.ishidden {
    display: none;
}

.model-block .black-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    min-height: 890px;
}

.model-block .modal-header button {
    padding: 0;
}

.model-block .model-cont {
    background: #fff;
    /* position: absolute;
  top: 100px;
  left: calc((100% - 800px)/2);
  width:800px; */
    position: fixed;
    top: 130px;
    left: calc((100% - 470px)/2);
    width: 700px;
    max-height: 500px;
    overflow-y: auto;
}

.model-block .model-head {
    height: 50px;
    line-height: 50px;
    font-size: 18px;
    color: #333;
    padding: 0 20px;
    border-bottom: 1px solid #e8e8e8;
    position: fixed;
    background: #fff;
    width: 660px;
    z-index: 1000;
}

.model-block .model-head button {
    border: none;
    width: auto;
    font-size: 22px;
    height: 100%;
    padding: 0;
}

.model-block .model-content {
    text-align: center;
    font-size: 16px;
    line-height: 30px;
    padding: 0 60px 20px;
    margin-top: 61px;
}

.model-content .sign-list .calendar-img {
    top: 10px;
}

.model-content .sign-list.sign-date img:nth-child(3) {
    right: 30px;
}

.model-content .sign-list.sign-date img {
    right: 250px;
}

.model-content .sign-list {
    margin-top: 10px;
    height: 36px;
}

.model-content .sign-list span {
    height: 36px;
    line-height: 36px;
    font-size: 15px;
}

.model-content .sign-list input {
    height: 36px;
    line-height: 36px;
    font-size: 15px;
}

.model-content .sign-list.sign-intro {
    height: 90px;
    margin-bottom: 0;
}

.model-content .sign-intro textarea {
    width: 408px;
    height: 90px;
}

.model-content .sign-list.sign-img {
    height: 166px;
}

.model-content .sign-img label {
    height: 136px;
}

.model-content .sign-img label img {
    margin: 30px 0 20px 0;
}

.model-content .sign-list.sign-img span {
    line-height: 166px;
}

.model-content .sign-list .selected-text {
    height: 34px;
    line-height: 34px;
}

.model-content .sign-list.select-list select {
    height: 34px;
    line-height: 34px;
}

.model-block .model-list {
    margin: 10px 0;
}

.model-block .model-list .left:nth-child(1) {
    width: 70px;
    padding-right: 10px;
}

.model-block .model-list .left:nth-child(2) {
    text-align: left;
    max-width: 496px;
    color: #333;
}

.model-block .model-foot {
    padding: 0 0 20px 0;
    text-align: center;
}

.model-block .model-foot button {
    margin-right: 20px;
    height: 40px;
    min-width: 180px;
    font-size: 16px;
    border-radius: 4px;
}


/* 宣讲会报名 */

.sign-block {
    width: 950px;
    margin: 0 auto;
    background: #ffffff;
    padding-top: 30px;
}

.sign-list {
    margin: 30px 0 0 0px;
    position: relative;
}

.sign-list .calendar-img {
    width: 18px;
    height: 18px;
    position: absolute;
    top: 8px;
    right: 266px;
}

.sign-list span {
    display: inline-block;
    width: 130px;
    height: 34px;
    line-height: 34px;
    color: #B1B4BE;
    text-align: right;
    vertical-align: top;
    font-size: 15px;
}

.sign-list span.purple {
    width: auto;
    color: #5e4fff;
    padding-left: 10px;
}

.sign-list span i {
    font-style: normal;
    font-size: 12px;
    color: #f4523b;
}

.sign-list input {
    width: 400px;
    height: 34px;
    border: 1px solid #EAEDF1;
    padding-left: 5px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 15px;
}

.sign-list .placholder-text {
    width: 400px;
    height: 34px;
    border: 1px solid #EAEDF1;
    padding-left: 5px;
    display: inline-block;
    line-height: 34px;
    color: #999;
    cursor: pointer;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    vertical-align: middle;
    font-size: 15px;
}

.sign-list .selected-text {
    width: 400px;
    height: 34px;
    border: 1px solid #EAEDF1;
    padding-left: 5px;
    display: inline-block;
    line-height: 34px;
    cursor: pointer;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #333;
    font-size: 15px;
    vertical-align: middle;
}

.sign-list .selected-text1 {
    width: 400px;
    padding: 8px 0 0 5px;
    border: 1px solid #EAEDF1;
    color: #333;
    font-size: 15px;
    display: flex;
    flex-wrap: wrap;
    min-height: 34px;
}

.sign-list .selected-text.disabled-text {
    cursor: default;
}

.sign-list .selected-text span {
    display: inline;
    color: #333;
}

.sign-list select {
    width: 400px;
    margin-top: 0 !important;
    border: 1px #EAEDF1 solid;
    height: 34px;
    padding-left: 5px;
    padding-right: 10px;
    font-size: 15px;
    appearance: none;
    /* 去掉默认图标 */
    -moz-appearance: none;
    /* Firefox */
    -webkit-appearance: none;
    /* Safari and Chrome */
    background: url("./assets/images/cardDown_2.png") no-repeat scroll right center transparent;
    /* 自己的图*/
}

.remark-text {
    padding: 0;
    font-size: 10px;
    color: #FF5555;
    margin-left: 270px;
}

.sign-list select:nth-child(3) {
    margin-right: 0;
}

.sign-list.select-list select {
    width: 410px;
    margin-right: 0;
    cursor: pointer;
    /* font-size: 14px; */
}

.sign-list.select-list.sign-address button {
    border: 1px solid #5e4fff;
    color: #5e4fff;
    margin-left: 15px;
}

.sign-list b {
    padding-left: 8px;
    font-weight: normal;
    font-size: 12px;
    color: #f4523b;
    display: inline-block;
    vertical-align: middle;
}

.sign-list b.remind-red {
    font-size: 18px;
    padding: 0 2px 0 0;
}

.sign-list s {
    text-decoration: none;
    color: #f36270;
    padding-left: 120px;
    line-height: 30px;
}

.sign-list.sign-img {
    height: 210px;
}

.sign-list.sign-img span {
    line-height: 210px;
}

.sign-img label {
    display: inline-block;
    width: 240px;
    height: 182px;
    background: #f7f7f7;
    border-radius: 4px;
    text-align: center;
    cursor: pointer;
}

.sign-img label img {
    margin: 50px 0 30px 0;
}

.sign-img label.department-logo {
    line-height: 182px;
    overflow: hidden;
}

.sign-img label.department-logo img {
    margin: 0;
    max-width: 100%;
    height: 100px;
}

.sign-list.sign-intro {}

.sign-button {
    text-align: right;
    padding-right: 80px;
    margin-top: 20px;
}

.sign-list .uploadImg {
    height: 0;
}

.sign-intro textarea {
    width: 400px;
    height: 130px;
    border: 1px solid #EAEDF1;
    font-size: 16px;
    overflow: auto;
    padding: 5px;
    position: relative;
}

.sign-list.sign-date input {
    width: 192px;
}

.sign-list.sign-date input:nth-child(2) {
    margin-right: 20px;
}

.sign-list.sign-date img:nth-child(3) {
    right: 600px;
}

.sign-list.sign-path {
    height: auto;
}

.path-block {
    display: inline-block;
}

.path-list {
    margin-bottom: 20px;
}

.path-list span {
    width: 60px;
}

.path-list img {
    cursor: pointer;
}

.sign-list.sign-skill {
    height: auto;
}

.sign-list .label-list {
    display: inline-block;
    margin-top: 5px;
    width: calc(100% - 140px);
}

.sign-list .label-item.purple {
    background: #5e4fff;
    color: #fff;
}

.sign-list .label-item.label-click {
    cursor: pointer;
}

.sign-submit {
    height: 68px;
    padding: 70px 0;
    text-align: center;
}

.sign-submit button {
    width: 260px;
    height: 56px;
    font-size: 20px;
    color: #fff;
}

.sign-submit button.yellow {
    margin-left: 20px;
}


/* 高校详情样式 */

.college-list {
    width: 900px;
    margin: 0 auto;
    padding-bottom: 20px;
    border-top: 1px solid #edf1f4;
}

.college-title {
    padding: 40px 0 20px 35px;
    font-size: 18px;
    color: #333;
}

.college-message {
    padding-left: 70px;
    line-height: 30px;
    font-size: 16px;
    padding-bottom: 30px;
}

.college-message .left:nth-child(1) {
    width: 100px;
    padding-right: 10px;
    text-align: left;
}

.college-message .left:nth-child(2) {
    color: #333;
}


/* 操作按钮部分 */

.college-opera {
    height: 50px;
    line-height: 50px;
    text-align: right;
    padding-bottom: 20px;
}

.college-opera button {
    margin-right: 20px;
}


/* 双选会详情样式 */

.college-intro-block {
    width: 900px;
    margin: 0 auto;
    margin-top: 70px;
}

.college-intro-title {
    font-size: 17px;
    color: #333;
    padding: 0 0 30px 35px;
}

.college-intro-block .college-message {
    font-size: 14px;
}


/* 简历列表 */

.resume-block {
    background: #fff;
}

.resume-block .college-block-opera .right select {
    margin-right: 20px;
}

.resume-block ul {
    padding: 0 20px;
    background: #fff;
    height: 80px;
    /*line-height: 80px;*/
}


/* 多级选择器样式 */

.first-level {
    width: 40%;
    height: 408px;
    float: left;
    background: #f1f1f1;
    margin-top: 51px;
}

.first-level .list-block {
    line-height: 50px;
    padding-left: 15px;
    background: #f1f1f1;
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding-right: 10px;
    cursor: pointer;
}

.first-level .list-block .active-pic {
    width: 15px;
    height: 50px;
    position: absolute;
    left: 0;
    top: 0;
    overflow: hidden;
}

.first-level .list-block .active-pic img {
    display: block;
    width: 4px;
    margin: 24px auto 0;
}

.first-level .list-block:after {
    content: "";
    display: block;
    position: absolute;
    left: -50%;
    bottom: 0;
    width: 200%;
    height: 1px;
    background: #d8d7e2;
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
}

.first-level .activeAddress {
    background: #fff;
}

.secend-level {
    width: 60%;
    height: 408px;
    float: left;
    position: relative;
    z-index: 999;
    box-shadow: -2px 2px 4px #ccc;
    margin-top: 51px;
}

.secend-level .list-block {
    line-height: 50px;
    background: #fff;
}

.secend-level .list-text {
    padding-left: 15px;
    padding-right: 30px;
    position: relative;
    cursor: pointer;
}

.secend-level .list-block .list-text .active-pic {
    width: 15px;
    height: 50px;
    position: absolute;
    left: 0;
    top: 0;
    overflow: hidden;
    background: #fff;
}

.secend-level .list-block .list-text .active-pic img {
    display: block;
    width: 4px;
    margin: 24px auto 0;
}

.secend-level .list-block .list-text .right-active-pic {
    width: 30px;
    height: 50px;
    position: absolute;
    right: 0;
    top: 0;
    overflow: hidden;
    background: #fff;
}

.secend-level .list-block .list-text .right-active-pic img {
    display: block;
    width: 12px;
    margin: 24px 0 0 0;
}


/*子级 */

.child-level {
    width: 100%;
    background: #f9f9f9;
    overflow: hidden;
    transform: scale(1, 0);
    transform-origin: 0 0;
    transition: all 0.3s;
    height: 0;
}

.child-level.active {
    transform: scale(1, 1);
    height: auto;
}

.child-list {
    position: relative;
    padding-left: 30px;
    color: #333;
    cursor: pointer;
}

.child-list:after {
    content: "";
    display: block;
    position: absolute;
    left: -50%;
    bottom: 0;
    width: 200%;
    height: 1px;
    background: #d8d7e2;
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
}

.child-list.activeChild {
    color: #5e4fff;
}

.list-block.activeAddress .list-text {
    background: #e7e7e7;
}


/* 底部保存按钮 */

.select-submit {
    height: 96px;
    line-height: 96px;
    text-align: center;
}

.select-submit button {
    width: 240px;
    font-size: 16px;
    height: 50px;
    border-radius: 4px;
}

.select-submit button:nth-child(2) {
    margin-left: 10px;
}


/* 选中项样式 */

.choose-item {
    float: left;
    border: 1px solid #5e4fff;
    border-radius: 16px;
    padding: 5px 10px;
    margin: 0 10px 10px 0;
}

.choose-text {
    display: inline-block;
}

.choose-delete {
    display: inline-block;
    margin-left: 5px;
    color: #5e4fff;
    cursor: pointer;
}


/* 校园+模块部分样式 */

.college-head-details.clear {
    padding: 15px 20px;
    font-size: 16px;
    line-height: 36px;
}

.college-head-details .left i {
    font-style: normal;
}

.college-head-details .right img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin-right: 80px;
}

.backdrop {
    position: fixed !important;
}


/* 编辑器样式 */

.edui-editor {
    z-index: 100 !important;
}

.edui-notadd .edui-editor-iframeholder {
    height: 500px !important;
}

td {
    height: 37px;
}

.college-block-head .bold {
    font-weight: bold;
}

.power-title {
    padding: 15px 0 0 20px;
}

.college-tab {
    cursor: pointer;
}


/* 招聘日历样式 */


/* .layui-laydate-main{width:100%;}
div#layui-laydate1{width:70%;} */


/* .layui-laydate {
  width: 70%;
} */


/* span.ng-tns-c4-0.ng-star-inserted {
  line-height: 45px;
  font-size: 18px;
}
span.ui-calendar {
  width: 100%;
}
tr{
  height: 80px;
  cursor: pointer;
}
.ui-datepicker .ui-datepicker-header a{background-color: #5e4fff;}
.ui-datepicker.ui-widget .ui-datepicker-calendar td a.ui-state-active{background-color: #5e4fff;}
.ui-datepicker .ui-datepicker-calendar td:not(.ui-state-disabled) a:hover {background-color: #eeeeee;} */


/* 头像名字跳转样式 */

.linkLogo {
    cursor: pointer;
}

.linkName span {
    color: #6977eb;
    cursor: pointer;
}

.linkName span:hover {
    text-decoration: underline;
}


/* 返回键 */

.goBackHeader {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.goBack {
    padding: 0 10px;
    cursor: pointer;
}

.gobackbtn {
    position: absolute;
    cursor: pointer;
    top: 10px;
    left: 30px;
    z-index: 999;
    padding: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.flex_row_center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex_rowl {
    display: flex;
    justify-content: center;
}

.flex_row_cl {
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.flex_row_l {
    display: flex;
    justify-content: flex-start;
}

.flex_row_r {
    display: flex;
    justify-content: flex-end;
}

.flex_row_left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.flex_row_right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.flex_row_between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.flex_row_between_c {
    display: flex;
    /* align-items: center; */
    justify-content: space-between;
}

.flex_row_around {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.flex_col_between {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.flex_col_between_c {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
}

.flex_col_center {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.flex_col_around {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
}

.flex_col_around_c {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
}

.flex_col_around_l {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

.flex_col_left {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}

.flex_col_left_t {
    display: flex;
    flex-direction: column;
}

.flex_col_left_c {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.flex_col_right {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
}

.flex_row_between_end {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.batchBtn {
    margin-top: 10px;
    border-radius: 4px;
    transition: all 1s;
}

.bottomBox {
    background: #fff;
}

.selsectBox {
    margin-bottom: 20px;
    margin-left: 20px;
}

.selsectBox .seslectAll {
    margin-right: 30px;
}

.selsectBox input {
    width: 20px;
    height: 20px;
    margin-right: 4px;
}

.studentSelect {
    width: 0;
    margin-right: 0;
    transition: all .5s;
    cursor: pointer;
}

.studentSelect.cur {
    height: 18px;
    width: 18px;
    margin-right: 6px;
}

.guwen_bg {
    width: 100%;
    height: 120px;
    background-color: #F0F1FF;
    margin: 5px 0;
    overflow: hidden;
}

.guwen_bg_gray {
    width: 86%;
    height: 100px;
    background-color: #E2E3EE;
    margin-left: 7%;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: left;
}

.guwen_head {
    width: 80px;
    height: 80px;
    margin-left: 33px;
    min-width: 80px;
    min-height: 80px;
    border: 2px #FEBF58 solid;
}

.guwen_head_code {
    width: 80px;
    height: 80px;
    margin-left: 33px;
    min-width: 80px;
    min-height: 80px;
}

.guwen_infoDiv {
    width: 250px;
    height: 80px;
    margin-left: 5px;
}

.guwen_info_name {
    color: #666666;
    font-size: 16px;
}

.guwen_info_title {
    color: #5E4FFF;
    font-size: 14px;
    margin-left: 3px;
}

.guwen_info_wx {
    color: #666666;
    font-size: 12px;
    margin-left: 3px;
}

.guwen_info_Line {
    width: 2px;
    background-color: #BCBDCD;
    height: 80px;
    margin: 10px 40px
}

.guwen_info_rightText {
    font-size: 17px;
    color: #666666;
    margin-right: 37px;
    margin-left: 5px
}

.guwen_info_huizhang {
    /*width: 38px;height: 50px;position: absolute;margin-top: -25px; margin-left: 13px*/
    width: 27px;
    height: 20px;
    position: absolute;
    margin-top: -45px;
    margin-left: 31px;
}

.marginTop4 {
    margin-top: 4px;
}

.marginTop5 {
    margin-top: 5px;
}

.marginTop8 {
    margin-top: 8px;
}

.marginTop15 {
    margin-top: 15px;
}

.marginTop20 {
    margin-top: 20px;
}

.marginTop24 {
    margin-top: 24px;
}

.marginTop25 {
    margin-top: 25px;
}

.marginTop30 {
    margin-top: 30px;
}

.marginTop35 {
    margin-top: 35px;
}

.marginTop40 {
    margin-top: 40px;
}


/*950*/

.downLoad_right {
    appearance: none;
    /* 去掉默认图标 */
    -moz-appearance: none;
    /* Firefox */
    -webkit-appearance: none;
    /* Safari and Chrome */
    background: url("./assets/images/cardDown_2.png") no-repeat scroll right center transparent;
    /* 自己的图*/
}

.jiebieBottom {
    margin-bottom: 5px;
}

.bordeRadius {
    border-radius: 4px;
}

.new_telent {
    font-size: 12px;
    color: #999999;
    margin-left: 3px;
    height: 20px;
    line-height: 20px;
    background-color: #EEEEEE;
    border-radius: 2px;
    padding-left: 5px;
    padding-right: 5px;
    font-weight: 400;
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

.joinHover {
    font-size: 14px;
    font-weight: bold;
    color: #926B3A;
    cursor: pointer;
    background-size: 200%;
    background-image: linear-gradient(to left, #DDB581, #EDCD94, #DDB581);
    -webkit-transition: all .4s ease;
}

.joinHover:hover {
    background-position: right;
}

.blueStreamer {
    font-size: 14px;
    font-weight: bold;
    color: #FFF;
    cursor: pointer;
    background-size: 200%;
    background-image: linear-gradient(to left, #4342FF, #517BE1, #4342FF);
    -webkit-transition: all .4s ease;
}

.blueStreamer:hover {
    background-position: right;
}

.richText img {
    width: 500px !important;
}

._positionPush {
    margin-top: 5px;
    font-size: 12px;
    color: #798F95;
}

._positionPush span {
    color: #3063FF;
    margin-left: 5px;
    cursor: pointer;
}

._positionPush span:hover {
    text-decoration: underline
}

.showTopingBox.active {
    width: 455px;
    height: 240px;
    background: url(./assets/images/v3/rightBg.png) right top #FFFFFF no-repeat;
    border-radius: 10px;
    position: relative;
    padding: 30px;
}

.showTopingBox.active .title .icon,
.showTopingBox.active .title .icon img {
    width: 53px;
    height: 53px;
}

.showTopingBox.active .title .tipText .name {
    font-size: 16px;
    font-weight: bold;
    color: #444444;
}

.showTopingBox.active .title .tipText .text {
    font-size: 12px;
    color: #96AEB5;
    margin-top: 6px;
}

.showTopingBox.active .closIcon {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
}

.showTopingBox.active .closIcon img {
    width: 20px;
    height: 20px;
}

.showTopingBox.active .p2 {
    font-size: 16px;
    color: #93A0A4;
    text-align: center;
    margin-top: 30px;
}

.showTopingBox.active .btns {
    display: flex;
    justify-content: center;
    margin-top: 67px;
}

.showTopingBox.active .btns .btn {
    text-align: center;
    font-size: 14px;
    color: #FFFFFF;
    width: 176px;
    height: 40px;
    border-radius: 8px;
    line-height: 40px;
    cursor: pointer;
    margin: 0 8px;
}

.showTopingBox.active .btns .btn.active {
    border: 1px solid #eeeeee;
    color: #747474;
}